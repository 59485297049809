import React from "react";
import css from './Disclaimer.module.css'

import equalHousingIcon from '../../assets/equalHousing.png'
import { FormattedMessage } from "react-intl";

const Disclaimer = (props) => {
    const { disclaimers } = props

    return <>
        <div className={css.disclaimersComponent}>
            <div className={css.disclaimersTitle}>
                <FormattedMessage id="ProfilePage.disclaimers" />
            </div>
            <div className={css.disclaimersDescription}>
                <img src={equalHousingIcon} className={css.equalHousingIcon} />
                <div >{disclaimers}</div>
            </div>

        </div>
    </>
}

export default Disclaimer